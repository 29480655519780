import "styles/globals.css";

import { Provider } from "next-auth/client";
import getConfig from "next/config";

import * as Sentry from "@sentry/node";
import { Integrations } from "@sentry/tracing";

const { publicRuntimeConfig } = getConfig();
const sentryConfig = publicRuntimeConfig.sentry;

import Layout from "components/Layout";

Sentry.init({
  enabled: sentryConfig.enabled,
  environment: sentryConfig.environment,
  dsn: sentryConfig.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});

function MyApp({ Component, pageProps }) {
  if (pageProps.session) {
    const user = pageProps.session.user;
    Sentry.setUser({ email: user.email });
  }

  return (
    <Provider session={pageProps.session}>
      <Layout session={pageProps.session}>
        <Component {...pageProps} />
      </Layout>
    </Provider>
  );
}

export default MyApp;
